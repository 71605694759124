/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */
 body {
  position: relative;
  height: 100vh;
 
  font-family: sans-serif;
}
#root{
  width: 100%;
  height: 100%;
}
@import "~bootstrap/dist/css/bootstrap.css";
